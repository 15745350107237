/** @link https://rsms.me/inter/inter.css */
/* ----------------------- Inter - static ----------------------- */

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url("/shared-assets/fonts/Inter-Regular.woff2?v=3.19") format("woff2"),
    url("/shared-assets/fonts/Inter-Regular.woff?v=3.19") format("woff");
  unicode-range: U+0000-00FF, /* Basic Latin, and Latin-1 Supplement */ U+0152-0153,
    /* Latin Extended-A */ U+02BB-02BC, /* spacing modifier letters */ U+2000-206F; /* general punctuation */
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: optional;
  src: url("/shared-assets/fonts/Inter-Italic.woff2?v=3.19") format("woff2"),
    url("/shared-assets/fonts/Inter-Italic.woff?v=3.19") format("woff");
  unicode-range: U+0000-00FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url("/shared-assets/fonts/Inter-Medium.woff2?v=3.19") format("woff2"),
    url("/shared-assets/fonts/Inter-Medium.woff?v=3.19") format("woff");
  unicode-range: U+0000-00FF, /* Basic Latin, and Latin-1 Supplement */ U+0152-0153,
    /* Latin Extended-A */ U+02BB-02BC, /* spacing modifier letters */ U+2000-206F; /* general punctuation */
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url("/shared-assets/fonts/Inter-Bold.woff2?v=3.19") format("woff2"),
    url("/shared-assets/fonts/Inter-Bold.woff?v=3.19") format("woff");
  unicode-range: U+0000-00FF, /* Basic Latin, and Latin-1 Supplement */ U+0152-0153,
    /* Latin Extended-A */ U+02BB-02BC, /* spacing modifier letters */ U+2000-206F; /* general punctuation */
}

/* ----------------------- Inter - variable ----------------------- */

@font-face {
  font-family: "Inter var";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("/shared-assets/fonts/Inter-roman.var.woff2?v=3.19") format("woff2");
  font-named-instance: "Regular";
}
@font-face {
  font-family: "Inter var";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("/shared-assets/fonts/Inter-italic.var.woff2?v=3.19") format("woff2");
  font-named-instance: "Italic";
}

/* ---------------------------------------------- */
/* ------------------ Tailwind ------------------ */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-e-color-background text-e-color-onBackground antialiased min-h-screen;
    /* !! TEMPORARY */
    /* @link https://tailwindcss.com/docs/font-family */
    font-family: "Inter", Helvetica, sans-serif;
  }

  @supports (font-variation-settings: normal) {
    body {
      font-family: "Inter var", Helvetica, sans-serif;
    }
  }

  ::selection {
    @apply bg-e-color-tertiaryContainer/70 text-e-color-onTertiaryContainer;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }
}

@layer components {
  .dialog-overlay[data-state="open"] {
    animation: fadeIn 300ms ease-out forwards;
  }

  .dialog-overlay[data-state="closed"] {
    animation: fadeOut 300ms ease-in forwards;
  }

  .dialog-content[data-state="open"] {
    animation: slideIn 300ms ease-out forwards;
  }

  .dialog-content[data-state="closed"] {
    animation: slideOut 300ms ease-in forwards;
  }

  [data-rehype-pretty-code-fragment],
  [data-code-fragment] {
    position: relative;
  }

  [data-rehype-pretty-code-fragment] pre,
  [data-code-fragment] pre {
    @apply py-6;
  }

  [data-rehype-pretty-code-fragment] pre > code {
    display: grid;
  }
}
